/* You can add global styles to this file, and also import other style files */
@import 'libs/core/src/styles';

:root {
  --primary: #102d53;
  --font-on-primary: #fff;
  --secondary: #fff;
  --background: #f5f6f8;
  --font-main: #002642;
  --font-secondary: #0a0a0a;
  --disabled: #cecbcb;
  --green: #048c04;
  --yellow: #f5d163;
  --red: #d10019;
}

@font-face {
  font-family: 'Oswald';
  src: local(''),
    url('/assets/fonts/oswald-v48-latin-regular.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Oswald';
  src: local(''),
    url('/assets/fonts/oswald-v48-latin-500.woff2') format('woff2'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Oswald';
  src: local(''),
    url('/assets/fonts/oswald-v48-latin-700.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  font-style: normal;
  font-weight: 700;
}


body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--font-main);
  font-family: Oswald, sans-serif;
}
